import React from 'react';
import { Card, CardContent, CardActions, CardHeader, Divider, Skeleton, Stack, Badge } from "@mui/material";
import { Box } from '@mui/system';

const AgentSkeletonLoader = () => (
    <CardContent className="!p-20">
        <Stack direction={'row'} gap={2}>
            <CardHeader
                className="p-0"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                }}
                avatar={<Skeleton animation="wave" variant="circular" width={140} height={140} />}
            />
            <Stack width={'100%'} className="mt-16">
                <Skeleton sx={{ height: 28, width: '50%' }} animation="wave" variant="text" />
                <Skeleton sx={{ height: 16, width: '30%' }} animation="wave" variant="text" />
                <Stack direction={'row'} gap={2} className="w-full mt-8" justifyContent={'flex-start'}>
                    <Skeleton sx={{ height: 65, width: '25%' }} animation="wave" variant="rectangular" className="rounded-4" />
                    <Skeleton sx={{ height: 65, width: '25%' }} animation="wave" variant="rectangular" className="rounded-4" />
                    <Skeleton sx={{ height: 65, width: '25%' }} animation="wave" variant="rectangular" className="rounded-4" />
                </Stack>
            </Stack>
        </Stack>
        <CardContent className="!p-0 px-16">
            <Box className="mt-8" />
            <Skeleton sx={{ height: 28, width: '70px' }} animation="wave" variant="text" />
            <Skeleton sx={{ height: 16 }} animation="wave" variant="text" />
            <Skeleton sx={{ height: 16 }} animation="wave" variant="text" />
            <Divider className="mb-16 mt-20 -mx-20" />
            <Stack direction={'row'} gap={2} className="w-full mt-8" justifyContent={'flex-start'}>
                <Skeleton sx={{ height: 16, width: '70px' }} animation="wave" variant="text" />
                <Skeleton sx={{ height: 16, width: '70px' }} animation="wave" variant="text" />
                <Skeleton sx={{ height: 16, width: '70px' }} animation="wave" variant="text" />
            </Stack>
        </CardContent>
    </CardContent>
);

const AgentMapsTabLoader = () => (
    <div className="">
        {/* Loader design for AgentMapsTabLoader */}
        <Stack direction={'row'} gap={3}>
            {/* Skeleton for the image */}
            <Box className="w-216 h-136">
                <Skeleton variant="rectangular" width={216} height={136} />
            </Box>

            {/* Skeleton for the text and content */}
            <Stack className="flex-1 pr-12">
                <CardHeader
                    className="p-0"
                    sx={{
                        "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    }}
                    avatar={
                        <Skeleton variant="circular" width={46} height={46} />
                    }
                    title={
                        <Skeleton sx={{ height: 28, width: '70%' }} animation="wave" variant="text" />
                    }
                    subheader={
                        <Skeleton sx={{ height: 16, width: '50%' }} animation="wave" variant="text" />
                    }
                />
                <CardContent style={{ cursor: 'pointer' }} className="p-0 !pb-0">
                    <Skeleton sx={{ height: 16, width: '100%' }} animation="wave" variant="text" />
                    <Skeleton sx={{ height: 16, width: '80%' }} animation="wave" variant="text" />
                </CardContent>

                {/* Skeleton for icons and counts */}
                <Stack
                    flex={1}
                    alignItems={'flex-end'}
                    direction="row"
                    gap={'18px'}
                    justifyContent={'start'}
                    className="w-full mt-6"
                >
                    <Skeleton sx={{ height: 30, width: '80px' }} animation="wave" variant="text" />
                    <Skeleton sx={{ height: 30, width: '80px' }} animation="wave" variant="text" />
                    <Skeleton sx={{ height: 30, width: '80px' }} animation="wave" variant="text" />
                    <div className='flex-1'></div>
                    <Skeleton sx={{ height: 30, width: '80px' }} animation="wave" variant="text" />
                </Stack>
            </Stack>
        </Stack>
    </div>
);

const AgentMissionActivityTabLoader = () => (
    <div className="">
        {/* Loader design for AgentMissionActivityTabLoader */}
        <Card
            className="p-16 pb-8 rounded-lg shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)] bg-pi-primary bg-opacity-10"
            elevation={0}
        >
            {/* Card Header Skeleton */}
            <CardHeader
                className="p-0"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                }}
                avatar={

                    <Skeleton variant="circular" width={46} height={46} />

                }
                title={
                    <Skeleton sx={{ height: 28, width: '50%' }} animation="wave" variant="text" />
                }
                subheader={
                    <Skeleton sx={{ height: 16, width: '30%' }} animation="wave" variant="text" />
                }
                action={null}
            />

            {/* Card Content Skeleton */}
            <CardContent className="p-0">
                <Skeleton sx={{ height: 28, width: '80%' }} animation="wave" variant="text" className="mt-5" />
                <Skeleton sx={{ height: 16, width: '90%' }} animation="wave" variant="text" className="mt-3" />
                <Skeleton sx={{ height: 16, width: '85%' }} animation="wave" variant="text" className="mt-3 mb-8" />
                <Box
                    sx={{ width: "100%", height: { xl: "22.416667vw", lg: "25.416667vw", md: "17.416667vw", sm: "24.416667vw", xs: "56.25vw" } }}
                    className="overflow-hiddenrounded-md"
                >
                    <Skeleton sx={{ height: "100%", width: "100%", }} animation="wave" variant="rectangular" className="rounded-md" />
                </Box>
            </CardContent>

            {/* Card Actions Skeleton */}
            <CardActions className="flex-col pt-2 pb-0 px-0 justify-start items-start mt-10">
                <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                    <Skeleton sx={{ height: 16, width: '80px' }} animation="wave" variant="text" />
                    <Skeleton sx={{ height: 16, width: '80px' }} animation="wave" variant="text" />
                    <Skeleton sx={{ height: 16, width: '80px' }} animation="wave" variant="text" />
                    <div className='flex-1'></div>
                    <Skeleton sx={{ height: 16, width: '80px' }} animation="wave" variant="text" className=" text-right" />
                </Stack>
            </CardActions>
        </Card>
    </div>
);

const AgentCommunityTabLoader = () => (
    <div className="p-20">
        {/* Loader design for AgentCommunityTabLoader */}
        <Skeleton sx={{ height: 50, width: '100%' }} animation="wave" variant="rectangular" />
    </div>
);

export default AgentSkeletonLoader; // Default export
export { AgentMapsTabLoader, AgentMissionActivityTabLoader, AgentCommunityTabLoader }; // Named exports
