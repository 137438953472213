import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Badge, Button, CardHeader, Grid, Paper, Typography, Avatar, Container, Stack, Icon, IconButton } from "@mui/material";
import { generatePath, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMissionById } from "../../store/missionDetailsSlice";
import PhotoGridView from "app/services/PhotoGridView";
import FuseLoading from "@fuse/core/FuseLoading";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import AttachmentSvs from "app/services/AttachmentSvs";
import SeeMoreCollapsible from './component/SeeMoreCollapsible ';
import JoinButtonSvs from "app/services/JoinButtonSvs";
import SharerSvs from "app/services/SharerSvs";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import CarouselView from "./component/CarouselView";
import MissionDetailsSkeletonLoader from "./component/MissionDetailsSkeletonLoader";
import { PinnedIcon } from "app/main/custom-icon/MissionCounterIcons";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LikeButton from "../../../friends-activity/components/LikeButton";
const styles = {
    gradientBackground: {
        paddingBottom: "70px",
        paddingTop: "30px",
        display: 'flex',
        maxWidth: '639px',
        minHeight: "fit-content",
        flexDirection: "column",
        justifyContent: 'start',
        background: 'white',
        backgroundImage: 'url("assets/images/pi-images/global-map.svg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    imageContainer: {
        '& .image-container': {
            paddingBottom: '42%',
            borderRadius: "unset !important",
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_2_1.image-container': {
            paddingBottom: '84%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_3_1.image-container': {
            paddingBottom: '21%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_4_1.image-container': {
            paddingBottom: '28%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },

    },
};

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
}));

function MissionDetailContent(params) {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getMissionById({ id: params.mission_id })).then((action) => {
            const data = action.payload;
            if (!data || data.status == "0") {
                navigate(generatePath("/public/home"));
            }
            setData(data)
            console.log('paramgetMissionByIdsssssssssss', data);
            setLoading(false)
        })
    }, [dispatch]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                handleScrollToTop();
            }
        };

        console.log('params', params);

        const handleScrollToTop = () => {
            params.activeKingPin();

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (loading) {
        return <MissionDetailsSkeletonLoader />;
    }

    return (
        < >
            <div id={data.id} className=" flex-grow basis-xs relative" style={styles.gradientBackground}>
                <Container>
                    <Typography className=" text-24 font-800">{data.title}</Typography>
                    <CardHeader
                        className="px-0 py-8"
                        sx={{
                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                            "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                        }}
                        avatar={
                            <>

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                    badgeContent={
                                        <span
                                        >
                                            <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                        </span>
                                    }
                                >
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${data.agent?.userId}`}>
                                        <AttachmentSvs module={"at-6"} path={data.agent?.profilePicPath} data={{ name: data.agent?.name }} />
                                    </Link>

                                </Badge>
                            </>
                        }
                        title={
                            <Typography className=" font-800 text-16">
                                <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${data.agent?.userId}`}>
                                    {data.agent.flName} <span className=" !font-500 text-16 text-pi-grey">created this mission</span>
                                </Link>
                            </Typography>
                        }
                        subheader={
                            <Typography className=" line-clamp-1 text-16 font-500  text-pi-grey">
                                <DateTimeDisplay dateTime={data.dateCreated} />
                            </Typography>
                        }
                    />
                    <SeeMoreCollapsible text={data.description} maxLength={225} />

                    <JoinButtonSvs agent_id={data.agent.userId} mission_id={params.mission_id} module={"at-3"} />
                </Container>
                <Stack className=" bg-pi-dark-blue bg-opacity-90 absolute bottom-0 w-full px-24 py-6" direction={'row'} justifyContent={"space-between"} alignItems={'center'} sx={{ '& .MuiButton-root': { color: 'white !important' } }}>
                    <Stack direction={'row'} gap={1.5} justifyContent={''} className=" !text-white items-center">
                        <div className="flex items-center">
                            {/* <LikeButton 
                                missionId={item.missionId}
                                userId={item.agent?.userId}
                                initialAction={item.action}
                                reloadActivities={reloadActivities}>    

                            </LikeButton> */}
                        </div>
                        <Typography className="h-fit">{data.views} views</Typography>
                        <Typography className="h-fit">{data.commentsCount} comments</Typography>
                        <Typography className="h-fit">{data.pinned} pinned</Typography>
                    </Stack>
                    <SharerSvs link={`/public/mission-details/${params.mission_id}`} title={data.title} description={data.description} />

                </Stack>
            </div>
            <Container id={data.id} className="flex-grow basis-xs p-0 max-w-full " sx={{ ...styles.imageContainer }} >
                {/* <PhotoGridView fileId={data.filesId} /> */}
                <CarouselView fileId={data.filesId} />
            </Container >
        </>

    )
}

export default MissionDetailContent;