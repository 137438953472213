import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import PublicHeader from "app/main/apps/public/public-header/PublicHeader";
import { GlobalStyles, height, minHeight } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { TextField, Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon, IconButton, Popover, Skeleton, TextareaAutosize } from "@mui/material";
import { cloneElement, memo, useCallback } from 'react';
import PublicSidebarLeft from "app/main/apps/public/public-sidebar-left/PublicSidebarLeft";
import { useDispatch, useSelector } from "react-redux";
import PublicSidebarLeftLogo from "app/main/apps/public/public-sidebar-left/PublicSidebarLeftLogo";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/agent/store';
import { Background, Parallax } from "react-parallax";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import { agentById, getMarkersByAgentId, getMissionJoined } from '../store/agentSlice';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import FriendsActivityJoinMissionModal from "../../public/friends-activity/modal/FriendsActivityJoinMissionModal";
import FriendsActivityCommentModal from "../../public/friends-activity/modal/FriendsActivityCommentModal";
import { PiMapPinLineBold } from "react-icons/pi";
import AgentFollowUnfollow from "app/services/AgentFollowUnfollow";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import FuseLoading from "@fuse/core/FuseLoading";
import { getMarkersByMissionId } from "../../public/mission/store/missionDetailsSlice";
import format from "date-fns/format";
import AttachmentSvs from "app/services/AttachmentSvs";
import SkeletonLoader from "../../public/friends-activity/components/SkeletonLoader";
import AgentSkeletonLoader from "./components/AgentSkeletonLoader";
import PhotoGridView from "app/services/PhotoGridView";
import SharerSvs from "app/services/SharerSvs";
import { position } from "stylis";
import { PinIcon, SeenIcon, CommentIcon, PinnedIcon, PinnedIconBlue, LikesIcon, CommunityIconBadge } from "../../../custom-icon/MissionCounterIcons"
import LazyLoad from "react-lazy-load";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { inputLabelClasses } from "@mui/material/InputLabel";
import Commentsv2 from "app/services/comments/Commentsv2";
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { saveCommunity, getCommunityList } from "../../public/community/store/CommunityManageSlice";
import AttachmentUploader from "app/services/AttachmentUploader";
import randomString from "app/services/randomString";
import { deletePublicAttachment } from "app/services/store/PublicUploadsSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import OtherAgents from "./content/OthersAgents";
import FilterButtonTabTwo from "./content/FilterButtonTabTwo";
import CreateMissionTabTwo from "./content/CreateMissionTabTwo";
import JoinMissionTabTwo from "./content/JoinMissionTabTwo";
import AllMissionTabTwo from "./content/AllMissionTabTwo";
import MapsContent from "./content/MapsContent";
import { Delete } from "@mui/icons-material";


const token = AgentJwtService.getDecodedAccessToken();

const Root = styled(FusePageSimple)(() => ({
    overscrollBehavior: 'contain',
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .agent-profile-follow": {
        marginTop: '4px'
    },
    "& .custom-textarea": {
        width: '100%',
        resize: 'none',
        borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
        padding: '8px',
        marginTop: '5px',
        outline: "none",
        minHeight: '25px'
    },
    "& .custom-textarea:focus": {
        borderBottom: "1.5px solid black",
        transition: "border - color 0.3s",
    }
}));
const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        fontFamily: "Nunito, sans-serif !important",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        backgroundColor: "unset",
        border: "1px solid #E9E9E9",
        fontSize: '14px',
        borderRadius: '18px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '14px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '14px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '14px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },

}));

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 16px;
  border: none;
  outline: none;
  background-color: transparent;
  color: #555; /* Default text color */

  &.Mui-selected {
    color: #1da1f2; /* Active tab text color (blue) */
    border-bottom: 2px solid #1da1f2; /* Blue border for active tab */
  }

  &:hover {
    color: #1da1f2;
  }

  

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  border: none; /* Explicitly remove border */
  background-color: transparent; /* Ensure background is transparent */
  box-shadow: none; /* Ensure no box shadow is applied */
  
`;

const TabsList = styled(TabsListUnstyled)`
  display: flex;
  border: none; /* Explicitly remove border */
  background-color: transparent; /* Ensure background is transparent */
  box-shadow: none; /* Ensure no box shadow is applied */
`;


function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
}));

const defaultValues = {
    id: null,
    title: "",
    featuredImageId: "",
    images: []
};


function AgentProfile(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [mapsdata, setMapsData] = useState([]);
    const [joinedMission, setjoinedMission] = useState([]);
    const [communityList, setcommunityList] = useState([]);
    const [missions, setMissions] = useState([]);
    const [missionsCreated, setMissionsCreated] = useState([]);
    const [missionsJoined, setMissionsJoined] = useState([]);
    const [value, setValue] = React.useState('1');
    const [tabvalue, setTabValue] = React.useState('1');
    const [loading, setLoading] = useState(true);
    const [missionLoading, setmissionLoading] = useState(true);
    const [isExpandedrep, setIsExpandedrep] = useState(false);
    const [attachmentTempId, setAttachmentTempId] = useState(randomString())
    const [attachmentData, setAttachmentData] = useState([])
    const [attachmentLoader, setAttachmentLoader] = useState(true)
    const [selectedFilter, setSelectedFilter] = useState('All')

    const handleDeleteAttachment = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentData.filter(item => item.id !== id);
            setAttachmentData(updatedAttachments);
        })
    };

    const toggleExpand = (itemId) => {
        setIsExpandedrep(prev => ({
            ...prev,
            [itemId]: !prev[itemId]
        }));
    };


    useEffect(() => {
        setAttachmentTempId(randomString())
        setAttachmentLoader(false)
        setLoading(true);
        setmissionLoading(true)

        const agentId = params.get("agentId") || token.id;

        // Fetch agent data
        dispatch(agentById(agentId)).then((action) => {
            setData(action.payload);
            console.log("agentById", action.payload)

            // Fetch markers
            dispatch(getMarkersByAgentId({
                id: action.payload.id,
                type: (value === '1' ? 'missions' : 'markers')
            })).then((markersAction) => {
                console.log("MissionDetailContent", markersAction.payload);
                setMissions(markersAction.payload)
            });

            // Fetch community list
            dispatch(getCommunityList(agentId)).then((communityAction) => {
                console.log("getCommunityList", communityAction.payload);
                setcommunityList(communityAction.payload);
            });

            // Set loading states to false after all data is fetched
            setLoading(false);
            setmissionLoading(false);
        });
    }, [dispatch, params.get("agentId"), token.id, value]);

    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Stack key={i} direction={'column'} gap={1.5} className=" w-full">
                    <SkeletonLoader />
                </Stack>
            );
        }
        return skeletonLoaders;
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded((prev) => !prev);
    };

    const [isFavorite, setIsFavorite] = useState(false);
    const [count, setCount] = useState(0);

    const handleClick = () => {
        setIsFavorite(!isFavorite);
        setCount(count + 1);
    };

    const schema = yup.object().shape({
        title: yup.string()
            .required("Title is required")
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        watch,
        trigger
    } = methods;

    const fetchMissionsAll = () => {

        const agentId = params.get("agentId") || token.id;

        dispatch(agentById(agentId)).then((action) => {
            setData(action.payload);
            setLoading(false);
            console.log("agentById", action.payload)

            // Now that we have the data, we can fetch markers
            dispatch(getMarkersByAgentId({
                id: action.payload.id, // Use the id from the fetched data
                type: "All"
            })).then((markersAction) => {
                console.log("MissionDetailContent", markersAction.payload);
                setLoading(false);
                setmissionLoading(false)
                setMissionsCreated(markersAction.payload)
            });
        });


    };

    const fetchJoinedMissionsCreated = () => {
        const agentId = params.get("agentId") || token.id;

        dispatch(agentById(agentId)).then((action) => {
            setData(action.payload);
            setLoading(false);
            console.log("agentById", action.payload)

            // Now that we have the data, we can fetch markers
            dispatch(getMarkersByAgentId({
                id: action.payload.id, // Use the id from the fetched data
                type: (value === '2' ? 'missions' : 'markers')
            })).then((markersAction) => {
                console.log("MissionDetailContent", markersAction.payload);
                setLoading(false);
                setmissionLoading(false)
                setMissionsCreated(markersAction.payload)
            });
        });
    };



    const fetchJoinMissions = () => {
        const agentId = params.get("agentId") || token.id;

        dispatch(agentById(agentId)).then((action) => {
            setData(action.payload);
            console.log("agentById", action.payload);

            // Fetch markers by agent ID
            dispatch(getMarkersByAgentId({
                id: action.payload.id,
                type: "markers"
            })).then((markersAction) => {
                setMissionsJoined(markersAction.payload);
                setLoading(false);
                setmissionLoading(false);
            });
        });

    }

    useEffect(() => {
        if (selectedFilter === 'All') {
            fetchMissionsAll();
        } else if (selectedFilter === 'Created Mission') {
            fetchJoinedMissionsCreated();
        }
         else if (selectedFilter === 'Join Mission') {
            fetchJoinMissions();
        }
    }, [selectedFilter]);

    //FILTER
    const handleFilterChange = (filter) => {
        setSelectedFilter(filter)

        if (filter === 'All') {
            fetchMissionsAll();
            console.log('All filter selected')
        } else if (filter === 'Created Mission') {
            fetchJoinedMissionsCreated();
            console.log('Mission filter selected      ')
        } else if (filter === 'Join Mission') {
            fetchJoinMissions();
        }
        // You can now use this filter value to update your component's logic
        console.log(`Selected filter in AgentProfile: ${filter}`);
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        setTabValue(newValue);
        setmissionLoading(false);
        setLoading(false);
    };

    async function handleSave() {
        // Trigger form validation
        const isValid = await trigger();

        if (isValid) {
            const agentId = params.get("agentId") || token.id;
            console.log('Form data:', getValues());
            var data = getValues();

            // Check if the title (post content) is not empty
            if (data.title && data.title.trim()) {
                data.fileId = attachmentTempId;
                data.userId = token.id;

                dispatch(saveCommunity(data)).then((action) => {
                    console.log('action.payload', action.payload);

                    if (tabvalue === '3') {
                        // Fetch community list
                        dispatch(getCommunityList(agentId)).then((communityAction) => {
                            console.log("getCommunityList", communityAction.payload);
                            setcommunityList(communityAction.payload);
                        });
                    }

                    setLoading(false);
                    reset(defaultValues);
                    setAttachmentTempId(randomString());
                    setAttachmentData([]);
                });
            } else {
                console.log('Cannot save empty post');
                // Optionally, you can set an error state or show a message to the user
            }
        } else {
            console.log('Form validation failed');
            // The form will display validation errors automatically
        }
    }

    const reloadActivities = useCallback(() => {
        setLoading(false);
        setMissionsCreated([]); // Clear existing activities
        fetchMissionsAll(0, 10);
    }, [fetchMissionsAll]);


    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    },
                    " .agent-profile-follow.w-fit.rounded-md.text-pi-black": {
                        color: '#656565 !important'
                    },
                })}
            />

            <Root
                content={
                    <>
                        {loading ? (
                            <Skeleton variant="rectangular" height={260} />
                        ) : (
                            <Box className="relative">
                                <div
                                    className='relative rounded-none overflow-hidden m-auto !shadow-0 bg-pi-light-grey'
                                    style={{ width: "100%", height: "260px" }}
                                >
                                    {data.length != 0 && (
                                        data.bannerUrl ? (
                                            <img className="max-w-none w-full h-full object-cover" src={data.bannerUrl} alt="Cover Photo" />
                                        ) : (
                                            <img className="max-w-none w-full h-full object-cover" src="assets/images/backgrounds/default-cover.png" alt="Cover Photo" />

                                        )
                                    )}
                                </div>
                            </Box>
                        )}
                        <Box className="w-full max1200px:w-xl md:w-10/12 sm:w-full p-16 mt-16 m-auto mb-32 min-h-screen">

                            {loading ? (
                                <Card className="mx-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                    <AgentSkeletonLoader />
                                </Card >
                            ) : (
                                <>
                                    {data.length !== 0 && token.id !== data.userId ? (
                                        null
                                    ) :
                                        <Stack direction={'row'} gap={.5} justifyContent={'flex-end'} className=" -mt-16 mb-16">
                                            <Button variant="contained" component={Link} to={'/public/mission-manager'} size="small" className="bg-pi-light-grey hover:bg-grey-400 rounded-md" startIcon={<Icon>insert_chart</Icon>}>
                                                Mission Manager
                                            </Button>
                                            <Button variant="contained" component={Link} to={'/agentprofile/edit'} size="small" className="bg-pi-light-grey hover:bg-grey-400 rounded-md" startIcon={<Icon>edit</Icon>}>
                                                Edit Page
                                            </Button>
                                        </Stack>}

                                    <Card className="shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                        <React.Fragment>
                                            <CardContent className="!p-24">
                                                <Stack direction={'row'} gap={2.5} className="relative">
                                                    <Stack>
                                                        <div>
                                                            {data.length != 0 && (
                                                                data.profileUrl ? (
                                                                    <Avatar sx={{ width: 136, height: 136 }} alt="user photo" src={data.profileUrl} className=" bg-pi-light-grey" />
                                                                ) : (
                                                                    // <Avatar style={{ width: 136, height: 136, fontSize: '42px', textTransform: 'capitalize' }} {...stringAvatar(data?.name)} />
                                                                    <Avatar sx={{ width: 136, height: 136 }} className=" border-1 border-pi-yellow-orange"></Avatar>
                                                                )
                                                            )}
                                                        </div>
                                                    </Stack>
                                                    <Stack flex={1}>
                                                        <Stack direction={'row'} alignItems={'center'} gap={.5} >
                                                            <Typography className=" text-24 font-extrabold -mb-5 "> {`${data.firstName || ''} ${data.middleName ? data.middleName + ' ' : ''} ${data.lastName || ''}`}</Typography>
                                                            {data.length !== 0 && token.id !== data.userId ? (
                                                                <AgentFollowUnfollow className=' h-fit ' item={data} module='agent-profile' />
                                                            ) : null}
                                                        </Stack>
                                                        <Typography className=" text-16 font-bold text-pi-grey">@{data.username} <span className=" font-normal text-15">{data.agentFollowersCount} followers</span></Typography>
                                                        <Stack direction={'row'} gap={3} className=" w-full mt-16" justifyContent={'start'}>
                                                            <Box
                                                                className="px-10 py-3 font-bold rounded-md bg-default"
                                                                sx={{
                                                                    width: '25%',
                                                                    border: (theme) => `1px solid rgba(240,240,240)`,
                                                                }}
                                                            >
                                                                <Typography className=" text-12 font-bold text-pi-grey">Total Reach</Typography>
                                                                <Typography className=" text-20 font-extrabold">{data.getReach?.missions + data.getReach?.markers || ''}</Typography>
                                                            </Box>
                                                            <Box
                                                                className="px-10 py-3 font-bold rounded-md bg-default"
                                                                sx={{
                                                                    width: '25%',
                                                                    border: (theme) => `1px solid rgba(240,240,240)`,
                                                                }}
                                                            >
                                                                <Typography className=" text-12 font-bold text-pi-grey">Mission Reach</Typography>
                                                                <Typography className=" text-20 font-extrabold">{data.getReach?.missions}</Typography>
                                                            </Box>
                                                            <Box
                                                                className="px-10 py-3 font-bold rounded-md bg-default"
                                                                sx={{
                                                                    width: '25%',
                                                                    border: (theme) => `1px solid rgba(240,240,240)`,
                                                                }}
                                                            >
                                                                <Typography className=" text-12 font-bold text-pi-grey">Colab Reach</Typography>
                                                                <Typography className=" text-20 font-extrabold">{data.getReach?.markers}</Typography>
                                                            </Box>

                                                        </Stack>
                                                    </Stack>


                                                </Stack>
                                                {data.about && (
                                                    <>
                                                        <Typography className="text-24 font-extrabold mt-8 -mb-4 line-clamp-3">Intro</Typography>
                                                        <Typography className={isExpanded ? "line-clamp-none text-16 text-pi-grey cursor-pointer leading-tight mb-24" : "text-16 text-pi-grey line-clamp-3 cursor-pointer leading-tight mb-24"} onClick={toggleReadMore} gutterBottom>{data.about}</Typography>
                                                    </>
                                                )}

                                                <Divider className=" mb-16 mt-20 -mx-20" />
                                                <Stack>
                                                    <TabsUnstyled value={value} onChange={(e, newValue) => handleChange(newValue)} defaultValue={value}>
                                                        <Box sx={{
                                                            width: '100%',
                                                            border: 'none', // Remove grey border here
                                                            boxShadow: 'none', // Remove any shadow as well
                                                        }} className="-mb-24">
                                                            <TabsList aria-label="lab API tabs example" sx={{ '.Mui-selected': { borderBottom: 'unset !important' } }} >
                                                                <Tab value="1" onClick={() => handleChange('1')} label="Maps" className={value === '1' ? 'font-bold !pb-16' : 'font-normal !pb-16'}>
                                                                    Maps
                                                                    {value === "1" && (
                                                                        <div className="h-5 bg-tab-blue rounded-t-4 mt-10 -mx-5 -mb-16"></div>
                                                                    )}
                                                                </Tab>
                                                                <Tab value="2" onClick={() => handleChange('2')} label="Mission Activity" className={value === '2' ? 'font-bold !pb-16' : 'font-normal !pb-16'}>
                                                                    Mission Activity
                                                                    {value === "2" && (
                                                                        <div className="h-5 bg-tab-blue rounded-t-4 mt-10 -mx-5 -mb-16"></div>
                                                                    )}
                                                                </Tab>
                                                                <Tab value="3" onClick={() => handleChange('3')} label="Community" className={value === '3' ? 'font-bold !pb-16' : 'font-normal !pb-16'}>
                                                                    Community
                                                                    {value === "3" && (
                                                                        <div className="h-5 bg-tab-blue rounded-t-4 mt-10 -mx-5 -mb-16"></div>
                                                                    )}
                                                                </Tab>
                                                            </TabsList>
                                                            <TabPanel value="1"></TabPanel>
                                                            <TabPanel value="2"></TabPanel>
                                                            <TabPanel value="3"></TabPanel>
                                                        </Box>

                                                    </TabsUnstyled >
                                                </Stack>

                                            </CardContent>

                                        </React.Fragment>
                                    </Card >
                                </>
                            )}

                            {tabvalue == 1 && ( //Maps tab
                                <MapsContent missions={missions}></MapsContent>
                            )}

                            {tabvalue == 2 && ( //Mission Activity tab
                                <>
                                    <Stack gap={2.5} className="relative">
                                        <FilterButtonTabTwo onFilterChange={handleFilterChange} >/</FilterButtonTabTwo>
                                        <div className=" h-16"></div>
                                        {selectedFilter === 'All' && (
                                            <AllMissionTabTwo missions={missionsCreated} agentToken={token.id} agentId={data.userId} reloadActivities={reloadActivities} ></AllMissionTabTwo>
                                        )}

                                        {selectedFilter === 'Created Mission' && (
                                            <CreateMissionTabTwo missions={missionsCreated}></CreateMissionTabTwo>
                                        )}

                                        {selectedFilter === 'Join Mission' && (
                                            <JoinMissionTabTwo missions={missionsJoined} joined={joinedMission} agentToken={token.id} agentId={data.userId}></JoinMissionTabTwo>
                                        )}
                                    </Stack>
                                </>
                            )}
                            {/* end of tab 2 */}

                            {tabvalue == 3 && (
                                <Stack gap={1.5}>
                                    {token.id !== data.userId ? (
                                        <>
                                            <div className=" mt-16 ">

                                            </div>
                                        </>
                                    ) :

                                        <Card className="p-16 pb-8 rounded-lg mt-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                                            <Stack gap={1} direction={'row'}>
                                                <div className="relative">
                                                    <Badge
                                                        className="mt-6"
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        sx={{
                                                            '& .MuiBadge-anchorOriginBottomRightCircular': {

                                                                bottom: '18%',

                                                            }
                                                        }}
                                                        badgeContent={
                                                            <span
                                                            >
                                                                <CommunityIconBadge />

                                                            </span>
                                                        }
                                                    >
                                                        {
                                                            data.length != 0 && (
                                                                data.profileUrl ? (

                                                                    <Avatar alt="user photo" src={data.profileUrl} className=" bg-pi-light-grey"
                                                                        style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />
                                                                ) : (
                                                                    // <Avatar alt={"data?.name"} {...stringAvatar(data?.name)}
                                                                    //     className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />
                                                                    <Avatar className="border-1 border-pi-red" style={{ height: '46px', width: '46px' }}></Avatar>
                                                                )
                                                            )
                                                        }
                                                    </Badge>

                                                </div>

                                                <Stack gap={1} flex={1}>
                                                    <FormProvider {...methods}>
                                                        <Controller
                                                            name="title"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{ required: 'Post content is required' }}
                                                            render={({ field, fieldState: { error } }) => (
                                                                <>
                                                                    <TextareaAutosize
                                                                        {...field}
                                                                        maxRows={4}
                                                                        className="custom-textarea text-16 leading-tight"
                                                                        placeholder="What's the latest on your mind?!"
                                                                    />
                                                                    {error && <p className="text-red-500 text-sm">{error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                        <Stack direction={'row'} justifyContent={'space-between'} className="my-10">
                                                            <AttachmentUploader module="community" id={attachmentTempId} attachmentData={attachmentData} setAttachmentData={setAttachmentData} />

                                                            {/* <Stack direction={'row'} gap={1} className="mt-10" flexWrap={'wrap'}>
                                                            {attachmentLoader ? (<FuseLoading />) :
                                                                attachmentData.length > 0 &&
                                                                attachmentData.map((item) => (
                                                                    <
                                                                ))
                                                            }

                                                        </Stack> */}
                                                            {/* <p style={{ display: 'flex', alignItems: 'center' }} className="cursor-pointer text-pi-blue-1 text-16">
                                                            <Icon className="mr-6">attachments</Icon>
                                                            Attached files
                                                        </p>
                                                         */}
                                                            {/* attachment */}
                                                            <Button onClick={handleSave} variant="contained" color="primary" size="small" className="font-bold">
                                                                Post
                                                            </Button>
                                                        </Stack>
                                                        <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
                                                            {attachmentLoader ? (<FuseLoading />) :
                                                                attachmentData.length > 0 &&
                                                                attachmentData.map((item) => (
                                                                    <Stack direction={'row'} gap={1.5} flexWrap={'wrap'}>
                                                                        <div
                                                                            style={{ width: 80, height: 80 }}
                                                                            className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                                                        >

                                                                            <Icon className='text-red-600 cursor-pointer absolute top-0 right-5 z-20'
                                                                                onClick={() => { handleDeleteAttachment(item.id) }}
                                                                            ><Delete /> </Icon>
                                                                            {
                                                                                (() => {
                                                                                    switch (item.switchType) {
                                                                                        case "youtube":
                                                                                            return <div className="image-container">
                                                                                                <DisabledPlayer>
                                                                                                    <div className=" z-10 disable"></div>
                                                                                                    <ReactPlayer
                                                                                                        light={true}
                                                                                                        playing={false}
                                                                                                        className="react-player rounded-lg overflow-hidden "
                                                                                                        url={item.srcUrl}
                                                                                                        height={80}
                                                                                                        width={80}
                                                                                                    />
                                                                                                </DisabledPlayer>
                                                                                            </div>;
                                                                                        case "video":
                                                                                            return <div className="image-container">
                                                                                                <video autoPlay={false} className="blog">
                                                                                                    <source
                                                                                                        type={item.fileType}
                                                                                                        src={item.srcUrl}
                                                                                                    />
                                                                                                </video>
                                                                                            </div>;
                                                                                        default:
                                                                                            return <div className="image-container2">
                                                                                                <img

                                                                                                    className="max-w-none w-full h-full object-cover"
                                                                                                    src={item.srcUrl}
                                                                                                />
                                                                                            </div>;
                                                                                    }
                                                                                })()
                                                                            }
                                                                        </div>
                                                                    </Stack>
                                                                ))
                                                            }

                                                        </Stack>
                                                    </FormProvider>
                                                </Stack>
                                            </Stack>
                                        </Card>

                                    }

                                    {loading ? (
                                        <Card className="p-16 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                            <Typography className="text-center text-pi-grey">
                                                Loading communities...
                                            </Typography>
                                        </Card>
                                        ) : communityList && communityList.length > 0 ? (
                                            communityList.map((item, index) => (
                                                
                                                <Card className="p-16 pb-8 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                    <CardHeader
                                                        className="p-0"
                                                        sx={{
                                                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                            // "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                                        }}
                                                        avatar={
                                                            <Badge
                                                                className="mt-6"
                                                                overlap="circular"
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                sx={{
                                                                    '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                                        bottom: '18%',
                                                                    }
                                                                }}
                                                                badgeContent={
                                                                    <span>
                                                                        <CommunityIconBadge />
                                                                    </span>
                                                                }
                                                            >
                                                                {data.length !== 0 && (
                                                                    data.profileUrl ? (
                                                                        <Avatar
                                                                            alt="user photo"
                                                                            src={data.profileUrl}
                                                                            className="bg-pi-light-grey"
                                                                            style={{ height: '46px', width: '46px', textTransform: 'capitalize' }}
                                                                        />
                                                                    ) : (
                                                                        <Avatar
                                                                            className="border-1 border-pi-red"
                                                                            style={{ height: '46px', width: '46px' }}
                                                                        />
                                                                    )
                                                                )}
                                                            </Badge>
                                                        }
                                                        title={
                                                            <Typography className=" font-extrabold text-18">
                                                                {item.agent?.firstName}
                                                            </Typography>
                                                        }
                                                        subheader={
                                                            <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                                {"@" + item.agent?.username} · Posted {item.timeAgo}
                                                            </Typography>
                                                        }
                                                    />
                                                    <CardContent>
                                                        <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                                            <Link
                                                                style={{ textDecoration: 'none' }}
                                                                to={`/public/community-details/${item.id}`}
                                                                state={{ item: item }}
                                                            >
                                                                <Typography
                                                                    className="text-16 transition duration-300 line-clamp-3 my-4 paragraph contents"
                                                                >
                                                                    {item.title}
                                                                </Typography>
                                                            </Link>
                                                        </Box>
                                                        {/* <PhotoGridView fileId={item.filesId} /> */}
                                                    </CardContent>

                                                    <CardActions className="flex-col pt-2 pb-0 px-0 justify-start items-start">
                                                        <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                                            <Typography className="gap-1 flex items-center">
                                                                <SeenIcon />
                                                                <span className="mt-1">{item.views} View{item.commentsCount !== 1 ? 's' : ''}</span>
                                                            </Typography>

                                                            <Link
                                                                style={{ textDecoration: 'none' }}
                                                                to={`/public/community-details/${item.id}`}
                                                                state={{ item: item }}
                                                            >
                                                                <Typography  className="gap-1 flex items-center cursor-pointer">
                                                                    <CommentIcon />
                                                                    <span className="mt-1">{item.commentsCount} Comment{item.commentsCount !== 1 ? 's' : ''}</span>
                                                                </Typography>
                                                            </Link>

                                                            {/* <Typography onClick={() => toggleExpand(item.id)} className="gap-1 flex items-center cursor-pointer">
                                                                <CommentIcon />
                                                                <span className="mt-1">{item.commentsCount} Comment{item.commentsCount !== 1 ? 's' : ''}</span>
                                                            </Typography> */}
                                                            
                                                            {/* <Typography className="gap-1 flex items-center">
                                                                <PinIcon />
                                                                <span className="mt-1">101 Pinned</span>
                                                            </Typography> */}
                                                            <Box className="flex-1 text-right">
                                                                <SharerSvs link={`/public/community-details/${item.id}`} title={item.title} description={""} />
                                                            </Box>
                                                        </Stack>
                                                       
                                                    </CardActions>
                                                    {/* {isExpandedrep && (
                                                        <Commentsv2 moduleType="communtiy" moduleId={item.missionId} />
                                                    )} */}

                                                    {/* {isExpandedrep[item.id] && (
                                                        <Commentsv2 moduleType="community" moduleId={item.id} />
                                                    )} */}
                                                </Card>
                                                ))
                                        ) : (
                                            <Card className="p-16 pb-8 rounded-lg mb-18  shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                                <CardContent className="p-0">
                                                    <Typography
                                                        component="div"
                                                        className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                                    >
                                                        No communities found.
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        )}

                                </Stack>
                            )}
                        </Box >
                    </>
                }
            />

        </>
    );
}

export default withReducer('agentApp', reducer)(AgentProfile);

