import { Badge, Box, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import AttachmentSvs from 'app/services/AttachmentSvs';
import DateTimeDisplay from 'app/services/DateTimeDisplay';
import PhotoGridView from 'app/services/PhotoGridView';
import TextLimit from 'app/services/TextLimit';
import React, { useRef, useEffect } from 'react';
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import { PinnedIconBlue } from 'app/main/custom-icon/MissionCounterIcons';

const JoinerList = ({ items, onItemCenter }) => {
    console.log("JoinerList")
    const observerRef = useRef();
    const itemRefs = useRef({});

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1, // Trigger when 50% of the element is in view n
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Call the function when item reaches the center
                    onItemCenter(itemRefs.current[entry.target.dataset.itemId].id);
                }
            });
        }, options);

        observerRef.current = observer;

        // return () => {
        //     // Cleanup observer on component unmount
        //     observer.disconnect();
        // };
    }, [onItemCenter]);

    useEffect(() => {
        // Observe each item in the list
        items.forEach((item) => {
            console.log("item", item)
            itemRefs.current[item.id] = item.ref;
            observerRef.current.observe(item.ref);
        });

        return () => {
            // Cleanup observer on component unmount
            items.forEach((item) => {
                observerRef.current.unobserve(itemRefs.current[item.id]);
            });
        };
    }, [items]);

    return (
        <div className='p-16 pt-0'>
            {items && items.map((item, index) => (      
                <div id={item.id} key={index} ref={(el) => (item.ref = el)} data-item-id={item.id}>


                    <Card className="p-16 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={index}>
                        <CardHeader
                            className="p-0"
                            sx={{
                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                            }}
                            avatar={
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                    badgeContent={
                                        <span
                                        >
                                            <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                                        </span>
                                    }
                                >
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                        <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={{ name: item.agent?.name }} />
                                    </Link>

                                </Badge>
                            }
                            title={
                                <Typography className=" font-extrabold text-16">
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                        {item.agent?.flName}
                                    </Link>
                                </Typography>
                            }
                            subheader={
                                <Typography className=" line-clamp-1 font-500 text-15 text-pi-grey">
                                    Joined - <DateTimeDisplay timeago={item.timeAgo} />
                                </Typography>
                            }
                            action={
                                <IconButton aria-label="settings" className="hidden" edge={false}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                        />
                        <CardContent className="p-0">
                            <Typography
                                component="div"
                                className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                            >
                                {item.title}
                            </Typography>
                            <Box className="flex items-end mb-6 relative">
                                <div className="text-16 mb-3">
                                    {item.description && (
                                        <TextLimit text={item.description} limit={200} />
                                    )}
                                </div>
                            </Box>
                        </CardContent>
                        <PhotoGridView fileId={item.filesId} />
                    </Card>
                </div>
            ))}
        </div>
    );
};

export default JoinerList;